import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, FormHelperText } from '@mui/material';
import { Translate } from '@hanssens/ht-translate';

import CustomerInfo from '../components/CustomerInfo';
import CustomerLogo from '../components/CustomerLogo';
import userApi from '../api/userApi';

import { TOPDESK_URI, TOPDESK_DEFAULT_URI } from '../config/config';

// Logos
import logoTopDesk from '../assets/images/Topdesk.svg';
import logoWingene from '../assets/images/logo.svg';

interface IMainProps {}

export interface IUser {
    id: string;
    firstName: string;
    surName: string;
    mobileNumber: string;
}

const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

const Main: React.FC<IMainProps> = (props) => {
    const [searchParams] = useSearchParams();
    const [user, setUser] = React.useState<IUser | null>(null);
    const [logo, setLogo] = React.useState<string | null>(null);

    const id = searchParams.get('id');
    /***********************************************************************
     * Startup
     ***********************************************************************/
    React.useEffect(() => {
        if (id && guidRegex.test(id)) {
            userApi
                .get(`user/${id}`)
                .then((response) => {
                    setUser(response.data);
                })
                .catch((err) => {
                    console.warn(err);
                });
        }
    }, [id]);

    React.useEffect(() => {
        if (id && guidRegex.test(id)) {
            userApi
                .get(`user/${id}/logo`)
                .then((response) => {
                    setLogo(response.data);
                })
                .catch((err) => {
                    console.warn(err);
                });
        }
    }, [id]);

    /***********************************************************************
     * Render
     ***********************************************************************/
    let link = '';

    if (id && guidRegex.test(id)) {
        link = TOPDESK_URI.replace('{value}', id);
    } else if (id && !guidRegex.test(id)) {
        link = TOPDESK_DEFAULT_URI;
    }

    return (
        <section id='content' className='h-100 p-5'>
            <div className='bg-white w-100 h-100 d-flex flex-column'>
                <div className=''>
                    {/* Customer info */}
                    <div id='info' className='d-flex flex-column'>
                        <div className='d-flex flex-row mb-3'>
                            <i className='fas fa-user text-green' />
                            <div className='fw-bold ms-3'>
                                <Translate id='main.contact' />
                            </div>
                        </div>

                        <div className='mb-4'>
                            <CustomerLogo logo={logo} />
                            <CustomerInfo user={user} />
                        </div>
                    </div>
                    {/* Buttons */}
                    <div id='buttons'>
                        <div>
                            <Button variant='contained' className='rounded-0' href={link} target='_blank' disabled={!id || !link}>
                                <div className='d-flex flex-row align-items-center justify-content-between w-100'>
                                    <img src={logoTopDesk} alt='logo-topdesk' id='logo-topdesk' />
                                    <div className='ms-3'>
                                        <Translate id='main.open' />
                                    </div>
                                </div>
                            </Button>
                            {id && link && !guidRegex.test(id) ? (
                                <FormHelperText id='topdesk-not-found-error' className='text-danger'>
                                    <span className='ps-3'>
                                        <Translate id='main.error' />
                                    </span>
                                </FormHelperText>
                            ) : null}
                        </div>
                    </div>
                </div>
                {/* Footer */}
                <div id='footer' className='mt-auto w-100 d-flex flex-row'>
                    <div className='ms-4'>
                        <img id='logo-gemeente-wingene' src={logoWingene} alt='logo-gemeente-wingene' />
                    </div>
                    <div className='mt-auto ms-auto text-hanssens'>powered by Hanssens Beyond Telecom</div>
                </div>
            </div>
        </section>
    );
};

export default Main;
